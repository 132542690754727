<!-- eslint-disable vue/no-mutating-props -->
<script setup lang="ts">
const props = defineProps<{ isOpen: boolean }>();

defineEmits<{
  (e: "close"): void
}>();

const gtm = useGtm();

watch(() => props.isOpen, (isOpen) => {
  if (isOpen) {
    if (gtm?.enabled() && gtm?.dataLayer()) {
      gtm.dataLayer().push({
        event: "spuntini_WebshopModal",
      });
    }
  }
});

const { locale } = useI18n();
</script>

<template>
  <div>
    <UModal v-model="props.isOpen" prevent-close>
      <div class="relative p-8" style="background: linear-gradient(0deg, #F3F1EC 0%, #F3F1EC 100%), #F8F7F4;">
        <UIcon name="i-ph-x" class="absolute right-2 top-2 cursor-pointer" @click="$emit('close')" />
        <div class="flex flex-col gap-lg">
          <UButton :to="`https://deerlijk.spuntini.be/${locale}/`" target="_blank">
            Webshop
          </UButton>
          <UButton :to="`https://roeselare.spuntini.be/${locale}/`" target="_blank">
            Webshop klanten Roeselare
          </UButton>
          <UButton to="https://webshop.isales.be/#/users/login" target="_blank">
            iSales
          </UButton>
        </div>
      </div>
    </UModal>
  </div>
</template>

<style scoped>
</style>
